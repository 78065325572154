import React from "react";
import Explicit from "shared/components/Explicit";
import I18n from "shared/lib/I18n";

const Isrc = ({ isrc, parental_advisory }) => {
    return (
        <>
            <h4
                className="mb-0 d-flex align-items-center"
                style={{ height: "3rem" }}
            >
                <I18n t={`recording.view.profileIsrc.head`} />
            </h4>
            <hr className="my-2 sticky-top" style={{ top: "37px" }} />
            <div className="row" style={{ minHeight: "3rem" }}>
                <div className="col">
                    <span
                        className="font-weight-bold"
                        style={{ fontSize: "1.3rem" }}
                    >
                        {isrc}
                        <Explicit
                            className="ml-1"
                            explicit={parental_advisory}
                        />
                    </span>
                </div>
            </div>
        </>
    );
};

export default Isrc;
