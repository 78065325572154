import React from "react";
import Loader from "shared/components/Loader";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Picture from "./Picture";
import BaS from "shared/components/Picture/Getty/BaS";
import "./Getty.css";

const Getty = ({
    isLoading,
    pictures,
    defaultSearch,
    getPictures,
    miscEntityId,
}) => {
    return (
        <div className="misc-entity-management-getty">
            <div className="row">
                <div className="col">
                    <Widget
                        title={`misc_entity.management.imageGetty.gallery.head`}
                        enableCollapse
                    >
                        <Widget.Body className="border-top">
                            {isLoading ? (
                                <Loader className="mt-4" />
                            ) : pictures.length === 0 ? (
                                <h3 className="text-center mt-4">
                                    <I18n t="misc_entity.management.imageGetty.gallery.empty" />
                                </h3>
                            ) : (
                                <div className="grid mt-4">
                                    {pictures.map((picture) => (
                                        <Picture
                                            key={`${picture.id_picture}-${picture.id_version}`}
                                            picture={picture}
                                            getPictures={getPictures}
                                        />
                                    ))}
                                </div>
                            )}
                        </Widget.Body>
                    </Widget>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <BaS
                        defaultSearch={defaultSearch}
                        onSuccess={getPictures}
                        creationParams={{ id_misc_entity: miscEntityId }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Getty;
