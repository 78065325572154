import React from "react";

import { matchPath, useLocation } from "react-router-dom";

const hasKey = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

const dig = (obj, keys) => {
    if (!obj) {
        return null;
    }

    const [first, ...rest] = keys;

    if (rest.length === 0) {
        if (hasKey(obj, first)) {
            return obj[first];
        }

        return null;
    }

    if (hasKey(obj, first)) {
        return dig(obj[first], rest);
    }

    return null;
};

const getLocale = (path, defaultLocale, pathname) => {
    const match = matchPath(
        {
            path,
            caseSensitive: false,
            end: false,
        },
        pathname
    );

    if (!match?.params) {
        return defaultLocale;
    }

    const { locale } = match.params;

    if (!locale) {
        return defaultLocale;
    }

    return locale;
};

const getValue = (
    path,
    defaultLocale,
    pathname,
    t,
    children,
    translations,
    missingText
) => {
    const locale = getLocale(path, defaultLocale, pathname);
    const splitT = t.split(".");

    if (hasKey(translations, locale)) {
        const translation = dig(translations[locale], splitT);

        if (translation !== null) {
            return translation;
        }
    }

    const defaultTranslation = dig(translations[defaultLocale], splitT);

    if (defaultTranslation !== null) {
        return defaultTranslation;
    }

    if (children !== undefined) {
        return children;
    }

    return missingText;
};

const getTranslation = (
    path,
    defaultLocale,
    pathname,
    t,
    args,
    children,
    translations,
    missingText
) => {
    const value = getValue(
        path,
        defaultLocale,
        pathname,
        t,
        children,
        translations,
        missingText
    );

    if (typeof value === "function") {
        return value(args);
    }

    return value;
};

const createI18n = (locales, translations, missingText = false) => {
    const [defaultLocale] = locales;
    const path = `/:locale`;

    const I18n = ({ children = undefined, t, args = undefined }) => {
        const { pathname } = useLocation();
        return (
            <>
                {getTranslation(
                    path,
                    defaultLocale,
                    pathname,
                    t,
                    args,
                    children,
                    translations,
                    missingText
                )}
            </>
        );
    };

    I18n.getTranslation = ({ pathname }, t, args = {}) =>
        getTranslation(
            path,
            defaultLocale,
            pathname,
            t,
            args,
            undefined,
            translations,
            missingText
        );

    return I18n;
};

export default createI18n;
