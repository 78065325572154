export const capitalizeAll = (string, delimiter = " ") => {
    if (typeof string !== "string") {
        return string;
    }
    return string
        .split(delimiter)
        .map((el) => {
            return el.charAt(0).toUpperCase() + el.slice(1);
        })
        .join(delimiter);
};

export const extractHostname = (url) => {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
        hostname = url.split("/")[2];
    } else {
        hostname = url.split("/")[0];
    }

    //find & remove port number
    hostname = hostname.split(":")[0];
    //find & remove "?"
    hostname = hostname.split("?")[0];

    return hostname;
};

export const isJson = (item) => {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    return typeof item === "object" && item !== null;
};
