import React from "react";
import I18n from "shared/lib/I18n";
import RecordingLink from "shared/components/RecordingLink";
import Loader from "shared/components/Loader";
import Explicit from "shared/components/Explicit";

const RelatedIsrc = ({ isrcs, isLoading }) => {
    return (
        <>
            {isLoading && (
                <div className="loader">
                    <Loader size="2x" />
                </div>
            )}
            <h4
                className="mb-0 d-flex align-items-center"
                style={{ height: "3rem" }}
            >
                <I18n t={`recording.view.profileRelatedIsrc.head`} />
            </h4>
            <hr className="my-2 sticky-top" style={{ top: "37px" }} />
            <div
                className="row"
                style={{ maxHeight: "5rem", overflow: "auto" }}
            >
                <div className="col">
                    {isrcs.map(
                        ({
                            id_recording,
                            isrc,
                            parental_advisory,
                            has_credits,
                            has_lyric,
                            has_audio,
                        }) => {
                            return (
                                <div
                                    key={isrc}
                                    className="d-flex align-items-center"
                                >
                                    <RecordingLink
                                        recordingId={id_recording}
                                        content={isrc}
                                    />
                                    <Explicit
                                        className="ml-1"
                                        explicit={parental_advisory}
                                    />
                                    {id_recording && (
                                        <>
                                            <span className="mx-1">-</span>
                                            <RecordingLink
                                                recordingId={id_recording}
                                                content={
                                                    <span
                                                        className={`badge badge-${
                                                            has_credits
                                                                ? "success"
                                                                : "secondary"
                                                        }`}
                                                    >
                                                        <I18n t="recording.view.profileRelatedIsrc.has_credits" />
                                                    </span>
                                                }
                                                part="credits"
                                                className="d-flex mr-1"
                                            />
                                            <RecordingLink
                                                recordingId={id_recording}
                                                content={
                                                    <span
                                                        className={`badge badge-${
                                                            has_lyric
                                                                ? "success"
                                                                : "secondary"
                                                        }`}
                                                    >
                                                        <I18n t="recording.view.profileRelatedIsrc.has_lyric" />
                                                    </span>
                                                }
                                                part="lyrics"
                                                className="d-flex mr-1"
                                            />
                                            <RecordingLink
                                                recordingId={id_recording}
                                                content={
                                                    <span
                                                        className={`badge badge-${
                                                            has_audio
                                                                ? "success"
                                                                : "secondary"
                                                        }`}
                                                    >
                                                        <I18n t="recording.view.profileRelatedIsrc.has_audio" />
                                                    </span>
                                                }
                                                part="audio_description"
                                                className="d-flex"
                                            />
                                        </>
                                    )}
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        </>
    );
};

export default RelatedIsrc;
