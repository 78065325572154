import React from "react";
import Widget from "shared/components/Widget";
import deezerIcon from "assets/static/images/deezer.png";
import qobuzIcon from "assets/static/images/qobuz.png";
import napsterIcon from "assets/static/images/napster.png";
import appleIcon from "assets/static/images/apple.png";
import spotifyIcon from "assets/static/images/spotify.png";
import musicbrainzIcon from "assets/static/images/musicbrainz.png";
import discogsIcon from "assets/static/images/discogs.png";
import Tippy from "@tippyjs/react";

const PartnerLink = ({
    deezer,
    qobuz,
    napster,
    itunes,
    spotify,
    musicbrainz,
    discogs,
    isLoading,
}) => {
    return (
        <Widget
            title="artist.view.profilePartnerLink.head"
            isLoading={isLoading}
        >
            <Widget.Body>
                <hr className="my-2" />
                {deezer.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img src={deezerIcon} width="25px" alt="Deezer" />
                        <span className="ml-2">Deezer</span>
                        <span className="mx-1">:</span>
                        {deezer.map(({ id, url }, index) => (
                            <Tippy key={id} content={url} trigger="mouseenter">
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
                {qobuz.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img src={qobuzIcon} width="25px" alt="Qobuz" />
                        <span className="ml-2">Qobuz</span>
                        <span className="mx-1">:</span>
                        {qobuz.map(({ id, url }, index) => (
                            <Tippy key={id} content={url} trigger="mouseenter">
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
                {napster.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img src={napsterIcon} width="25px" alt="Napster" />
                        <span className="ml-2">Napster</span>
                        <span className="mx-1">:</span>
                        {napster.map(({ id, url }, index) => (
                            <Tippy key={id} content={url} trigger="mouseenter">
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
                {itunes.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img src={appleIcon} width="25px" alt="Itunes" />
                        <span className="ml-2">Apple Music</span>
                        <span className="mx-1">:</span>
                        {itunes.map(({ id, url }, index) => (
                            <Tippy key={id} content={url} trigger="mouseenter">
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
                {spotify.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img src={spotifyIcon} width="25px" alt="Spotify" />
                        <span className="ml-2">Spotify</span>
                        <span className="mx-1">:</span>
                        {spotify.map(({ id, url }, index) => (
                            <Tippy key={id} content={url} trigger="mouseenter">
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
                {musicbrainz.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img
                            src={musicbrainzIcon}
                            width="25px"
                            alt="Musicbrainz"
                        />
                        <span className="ml-2">Musicbrainz</span>
                        <span className="mx-1">:</span>
                        {musicbrainz.map(({ id, url }, index) => (
                            <Tippy key={id} content={url} trigger="mouseenter">
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
                {discogs.length !== 0 && (
                    <div className="d-flex flex-wrap align-items-center mb-2">
                        <img src={discogsIcon} width="25px" alt="Discogs" />
                        <span className="ml-2">Discogs</span>
                        <span className="mx-1">:</span>
                        {discogs.map(({ id, url }, index) => (
                            <Tippy key={id} content={url} trigger="mouseenter">
                                <div className="mr-1">
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {++index}
                                    </a>
                                </div>
                            </Tippy>
                        ))}
                    </div>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default PartnerLink;
