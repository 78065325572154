import React, { useCallback, useMemo } from "react";
import { useLocation, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExchangeAlt,
    faLink,
    faInfo,
    faCopy,
} from "@fortawesome/free-solid-svg-icons";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeInteger,
    normalizeBarcode,
    normalizeTitle,
} from "shared/functions/normalize";
import {
    STATUS_MATCHED_IN_CURRENT_DISCOGRAPHY,
    STATUS_MATCHED_IN_OTHER_DISCOGRAPHY,
    STATUS_NOT_MATCHED_INCOMPLETE,
    STATUS_NOT_MATCHED_COMPLETE,
} from "pages/ArtistManagement/Discography/Sandbox/Sandbox.constants";
import Table from "./Table";

const EnhancedTable = ({
    showPopover,
    hidePopover,
    showMatchings,
    showTracklistComparison,
    showCopyProduct,
    partnerFilter,
    setPartnerFilter,
    statusFilter,
    setStatusFilter,
    albums,
    isLoading,
    searchQuery,
    setSearchQuery,
}) => {
    const location = useLocation();

    const { releaseId } = useParams();

    const hasSelectedRelease =
        releaseId !== null && typeof releaseId !== "undefined";

    const colorClass = {
        [STATUS_MATCHED_IN_CURRENT_DISCOGRAPHY]: "table-success",
        [STATUS_MATCHED_IN_OTHER_DISCOGRAPHY]: "table-danger",
        [STATUS_NOT_MATCHED_INCOMPLETE]: "table-warning",
    };

    // The state of this component changes each time a row is hovered in the
    // table. The declarations below use useCallback or useMemo to prevent the
    // table from being rerendered when this happens.
    const columns = useMemo(
        () => [
            {
                dataField: "partnerAbbreviatedName",
                text: "",
                headerTitle: (column, colIndex) =>
                    I18n.getTranslation(
                        location,
                        "artist.management.disco.sandbox.partner"
                    ),
                sort: true,
                searchable: false,
                headerStyle: { width: "2.5em" },
            },
            {
                dataField: "barcode",
                text: I18n.getTranslation(
                    location,
                    "artist.management.disco.sandbox.barcode"
                ),
                sort: true,
                searchable: true,
                headerClasses: "d-none d-xl-table-cell",
                classes: "d-none d-xl-table-cell",
                headerStyle: { width: "70%" },
                filterValue: normalizeBarcode,
            },
            {
                dataField: "title",
                text: I18n.getTranslation(
                    location,
                    "artist.management.disco.sandbox.title"
                ),
                sort: true,
                headerStyle: { width: "100%" },
                filterValue: normalizeTitle,
                formatter: (cellContent, row, rowIndex, data) => (
                    <div className="title-container">
                        {cellContent}
                        <div className={`actions ${colorClass[row.status]}`}>
                            {row.url !== null && (
                                <a
                                    className="btn btn-link p-1 mr-1"
                                    href={row.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title={data.viewProduct}
                                >
                                    <FontAwesomeIcon icon={faLink} fixedWidth />
                                </a>
                            )}
                            {data.hasSelectedRelease && (
                                <button
                                    className="btn btn-link p-1 mr-1"
                                    title={data.compareTracklists}
                                    onClick={() =>
                                        showTracklistComparison(
                                            row.partnerAbbreviatedName,
                                            row.id
                                        )
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faExchangeAlt}
                                        fixedWidth
                                    />
                                </button>
                            )}
                            {(row.status === STATUS_NOT_MATCHED_COMPLETE ||
                                row.status ===
                                    STATUS_NOT_MATCHED_INCOMPLETE) && (
                                <button
                                    className="btn btn-link p-1 mr-1"
                                    title={data.copyProduct}
                                    onClick={() => {
                                        showCopyProduct(row, albums);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCopy} fixedWidth />
                                </button>
                            )}
                            {row.matchedInOtherDiscography && (
                                <button
                                    className="btn btn-link p-1 mr-1"
                                    title={data.viewMatchings}
                                    onClick={() => {
                                        showMatchings(row.matchings);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faInfo} fixedWidth />
                                </button>
                            )}
                        </div>
                    </div>
                ),
                formatExtraData: {
                    hasSelectedRelease: hasSelectedRelease,
                    viewProduct: I18n.getTranslation(
                        location,
                        "artist.management.disco.sandbox.view_product"
                    ),
                    compareTracklists: I18n.getTranslation(
                        location,
                        "artist.management.disco.sandbox.compare_tracklists"
                    ),
                    viewMatchings: I18n.getTranslation(
                        location,
                        "artist.management.disco.sandbox.view_matchings"
                    ),
                    copyProduct: I18n.getTranslation(
                        location,
                        "artist.management.disco.sandbox.copyProduct.title"
                    ),
                },
            },
            {
                dataField: "nbTracks",
                text: I18n.getTranslation(
                    location,
                    "artist.management.disco.sandbox.nb_track"
                ),
                sort: true,
                searchable: true,
                headerClasses: "d-none d-xl-table-cell",
                classes: "d-none d-xl-table-cell",
                headerStyle: { width: "20%" },
                formatter: (cell) => (cell === 0 ? "-" : cell),
            },
        ],
        [location, hasSelectedRelease, albums]
    );

    const defaultSorted = useMemo(
        () => [
            {
                dataField: "title",
                order: "asc",
            },
        ],
        []
    );

    const pagination = useMemo(
        () =>
            paginationFactory({
                custom: true,
                hideSizePerPage: true,
                hidePageListOnlyOnePage: true,
                showTotal: true,
                sizePerPage: 500,
                nextPageTitle: I18n.getTranslation(
                    location,
                    "utils.pagination.next_page"
                ),
                prePageTitle: I18n.getTranslation(
                    location,
                    "utils.pagination.previous_page"
                ),
                firstPageTitle: I18n.getTranslation(
                    location,
                    "utils.pagination.first_page"
                ),
                lastPageTitle: I18n.getTranslation(
                    location,
                    "utils.pagination.last_page"
                ),
                paginationTotalRenderer: (from, to, size) => (
                    <span className="react-bootstrap-table-pagination-total">
                        <I18n
                            t="artist.management.disco.sandbox.pagination"
                            args={{ from, to, size }}
                        />
                    </span>
                ),
            }),
        [location]
    );
    const rowClasses = useCallback((row) => colorClass[row.status], []);

    const rowEvents = useMemo(
        () => ({
            onMouseEnter: (e, row) => showPopover(row, e.target.closest("tr")),
            onMouseLeave: hidePopover,
        }),
        [showPopover, hidePopover]
    );

    const filteredAlbums = useMemo(
        () =>
            albums.filter(
                (album) =>
                    (partnerFilter?.[album.partnerAbbreviatedName] ?? true) &&
                    (statusFilter?.[album.status] ?? true)
            ),
        [albums, partnerFilter, statusFilter]
    );

    return (
        <Table
            albums={filteredAlbums}
            isLoading={isLoading}
            columns={columns}
            pagination={pagination}
            defaultSorted={defaultSorted}
            rowClasses={rowClasses}
            rowEvents={rowEvents}
            partnerFilter={partnerFilter}
            setPartnerFilter={setPartnerFilter}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            onColumnMatch={onColumnMatchNormalized}
        />
    );
};

export default EnhancedTable;
