import React, { useState, useEffect, useCallback } from "react";
import PartnerLink from "./PartnerLink";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const EnhancedPartnerLink = (props) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const [state, setState] = useState({
        deezer: [],
        qobuz: [],
        napster: [],
        itunes: [],
        spotify: [],
        musicbrainz: [],
        discogs: [],
        isLoading: true,
    });

    const getDeezer = useCallback(() => {
        return api
            .get(`matching/ms/dz/${artistId}`)
            .then((candidates) => {
                setState((prev) => ({
                    ...prev,
                    deezer: candidates.filter(({ isMatching }) => isMatching),
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    const getQobuz = useCallback(() => {
        return api
            .get(`matching/ms/qb/${artistId}`)
            .then((candidates) => {
                setState((prev) => ({
                    ...prev,
                    qobuz: candidates.filter(({ isMatching }) => isMatching),
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    const getNapster = useCallback(() => {
        return api
            .get(`matching/ms/na/${artistId}`)
            .then((candidates) => {
                setState((prev) => ({
                    ...prev,
                    napster: candidates.filter(({ isMatching }) => isMatching),
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    const getItunes = useCallback(() => {
        return api
            .get(`matching/ms/it/${artistId}`)
            .then((candidates) => {
                setState((prev) => ({
                    ...prev,
                    itunes: candidates.filter(({ isMatching }) => isMatching),
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    const getSpotify = useCallback(() => {
        return api
            .get(`matching/ms/sp/${artistId}`)
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    spotify: response.filter(({ url }) => url),
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    const getMusicbrainz = useCallback(() => {
        return api
            .get(`matching/ms/mb/${artistId}`)
            .then((candidates) => {
                setState((prev) => ({
                    ...prev,
                    musicbrainz: candidates.filter(({ isMatching }) => isMatching),
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    const getDiscogs = useCallback(() => {
        return api
            .get(`matching/ms/dg/${artistId}`)
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    discogs: response,
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [artistId]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([
                getDeezer(),
                getQobuz(),
                getNapster(),
                getItunes(),
                getSpotify(),
                getMusicbrainz(),
                getDiscogs(),
            ]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [
        getDeezer,
        getQobuz,
        getNapster,
        getItunes,
        getSpotify,
        getMusicbrainz,
        getDiscogs,
    ]);

    return <PartnerLink {...props} {...state} />;
};

export default EnhancedPartnerLink;
