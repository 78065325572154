import React, { useState, useEffect, useCallback } from "react";
import PictureTagsModal from "./PictureTagsModal";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import "./PictureTagsModal.css";

const schema = yup.object().shape({
    2: yup.number().nullable().required("utils.form.required"),
    3: yup.number().nullable().required("utils.form.required"),
    4: yup.number().nullable().required("utils.form.required"),
    6: yup.number().nullable().required("utils.form.required"),
    7: yup.number().nullable().required("utils.form.required"),
});

const defaultTags = {
    2: null,
    3: null,
    4: null,
    6: null,
    7: null,
    shot_date: null,
    ms_artists: [],
    open_tags: [],
};

const EnhancedPictureTagsModal = ({
    id_picture,
    id_picture_version,
    show,
    handleHide,
    onSuccess,
    isShows,
    ...props
}) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [state, setState] = useState({
        isLoading: false,
        picture: null,
    });

    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: defaultTags,
    });

    const getPicture = useCallback(() => {
        if (show) {
            setState((prev) => ({
                ...prev,
                isLoading: true,
            }));
            api.get(
                `picture/${id_picture}`,
                id_picture_version
                    ? {
                          locale,
                          versionId: id_picture_version,
                      }
                    : { locale }
            )
                .then((response) => {
                    setState({
                        isLoading: false,
                        picture: response,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        isLoading: false,
                        picture: null,
                    });
                });
        }
    }, [show, id_picture, locale]);

    useEffect(getPicture, [getPicture]);

    useEffect(() => {
        if (!show) {
            reset(defaultTags);
        }

        if (show && state.picture?.tags) {
            reset({ ...defaultTags, ...state.picture.tags });
        }
    }, [reset, show, state.picture]);

    const [tags, setTags] = useState([]);
    const getTags = useCallback(() => {
        if (show) {
            api.get("picture/tags", { locale })
                .then((response) => {
                    setTags(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [show, locale]);

    useEffect(getTags, [getTags]);

    const [predictEnvironment, setPredictEnvironment] = useState({
        value: null,
        score: null,
    });
    const [predictCrop, setPredictCrop] = useState({
        value: null,
        score: null,
    });
    const [predictColour, setPredictColour] = useState({
        value: null,
        score: null,
    });
    const [predictMsArtists, setPredictMsArtists] = useState([]);
    const [predictFreeTags, setPredictFreeTags] = useState([]);

    const [showEnvironmentWarning, setShowEnvironmentWarning] = useState(0);
    const [showCropWarning, setShowCropWarning] = useState(0);
    const [showColourWarning, setShowColourWarning] = useState(0);

    const getPredictionEnvironment = useCallback(() => {
        if (show && id_picture_version) {
            api.get(`picture/${id_picture_version}/predict/environment`)
                .then((response) => {
                    setPredictEnvironment({
                        value: response.value,
                        score: response.score,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [show, id_picture_version]);

    useEffect(getPredictionEnvironment, [getPredictionEnvironment]);

    const getPredictionCrop = useCallback(() => {
        if (show && id_picture_version) {
            api.get(`picture/${id_picture_version}/predict/crop`)
                .then((response) => {
                    setPredictCrop({
                        value: response.value,
                        score: response.score,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [show, id_picture_version]);

    useEffect(getPredictionCrop, [getPredictionCrop]);

    const getPredictionColour = useCallback(() => {
        if (show && id_picture_version) {
            api.get(`picture/${id_picture_version}/predict/colour`)
                .then((response) => {
                    setPredictColour({
                        value: response.value,
                        score: response.score,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [show, id_picture_version]);

    useEffect(getPredictionColour, [getPredictionColour]);

    const getPredictionMsArtists = useCallback(() => {
        if (show && id_picture_version && !isShows) {
            api.get(`picture/${id_picture_version}/predict/msArtists`)
                .then((response) => {
                    setPredictMsArtists(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [show, id_picture_version, isShows]);

    useEffect(getPredictionMsArtists, [getPredictionMsArtists]);

    const getPredictionFreeTags = useCallback(() => {
        if (show && id_picture_version) {
            api.get(`picture/${id_picture_version}/predict/freeTags`)
                .then((response) => {
                    setPredictFreeTags(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    }, [show, id_picture_version]);

    useEffect(getPredictionFreeTags, [getPredictionFreeTags]);

    useEffect(() => {
        if (
            (!state.picture?.tags ||
                Object.keys(state.picture.tags).length === 0) &&
            tags.length > 0 &&
            predictEnvironment
        ) {
            const tagsEnvironment = tags.filter((item) => item.group.id === 2);
            if (tagsEnvironment && tagsEnvironment.length > 0) {
                const selectedTag = tagsEnvironment[0].tags.find(
                    (option) => option.key === predictEnvironment.value
                );
                if (selectedTag) {
                    setValue("2", selectedTag.value);
                    if (predictEnvironment.score < 0.5) {
                        setShowEnvironmentWarning(2);
                    }
                }
            }
        }
    }, [predictEnvironment]);

    useEffect(() => {
        if (
            (!state.picture?.tags ||
                Object.keys(state.picture.tags).length === 0) &&
            tags.length > 0 &&
            predictCrop
        ) {
            const tagsCrop = tags.filter((item) => item.group.id === 6);
            if (tagsCrop && tagsCrop.length > 0) {
                const selectedTag = tagsCrop[0].tags.find(
                    (option) => option.key === predictCrop.value
                );
                if (selectedTag) {
                    setValue("6", selectedTag.value);
                    if (predictCrop.score < 0.5) {
                        setShowCropWarning(6);
                    }
                }
            }
        }
    }, [predictCrop]);

    useEffect(() => {
        if (
            (!state.picture?.tags ||
                Object.keys(state.picture.tags).length === 0) &&
            tags.length > 0 &&
            predictColour
        ) {
            const tagsColour = tags.filter((item) => item.group.id === 3);
            if (tagsColour && tagsColour.length > 0) {
                const selectedTag = tagsColour[0].tags.find(
                    (option) => option.key === predictColour.value
                );
                if (selectedTag) {
                    setValue("3", selectedTag.value);
                    if (predictColour.score < 0.5) {
                        setShowColourWarning(3);
                    }
                }
            }
        }
    }, [predictColour]);

    useEffect(() => {
        if (!state.picture?.tags.ms_artists && predictMsArtists) {
            setValue("ms_artists", predictMsArtists);
        }
    }, [predictMsArtists]);

    useEffect(() => {
        if (!state.picture?.tags.open_tags && predictFreeTags) {
            setValue("open_tags", predictFreeTags);
        }
    }, [predictFreeTags]);

    useEffect(() => {
        if (!state.picture?.tags.shot_date) {
            const date_camera_shot = state.picture?.metas?.find(
                (meta) => meta.key === "date_camera_shot"
            );
            const date_created = state.picture?.metas?.find(
                (meta) => meta.key === "date_created"
            );
            if (date_camera_shot) {
                const value = date_camera_shot.value;
                setValue("shot_date", new Date(value));
            } else if (!date_camera_shot && date_created) {
                const value = date_created.value;
                setValue("shot_date", new Date(value));
            }
        }
    }, [state]);

    const shotDateWatch = watch("shot_date");

    useEffect(() => {
        if (shotDateWatch && tags.length > 0) {
            let year;
            let period;
            if (shotDateWatch instanceof Date) {
                year = shotDateWatch.getFullYear();
            } else {
                year = shotDateWatch.split("-")[0];
            }
            const tagsPeriod = tags.filter((item) => item.group.id === 4);
            if (tagsPeriod && tagsPeriod.length > 0) {
                tagsPeriod.forEach((item) => {
                    item.tags.forEach((tag) => {
                        const [startYear, endYear] = tag.label
                            .split("-")
                            .map(Number);
                        if (year >= startYear && year <= endYear) {
                            period = tag.value;
                            setValue("4", period);
                        }
                    });
                });
            }
        }
    }, [shotDateWatch]);

    useEffect(() => {
        if (tags.length > 0) {
            let quality_rank_value;
            const quality_rank = state.picture?.metas?.find(
                (meta) => meta.key === "quality_rank"
            );
            if (quality_rank) {
                quality_rank_value = quality_rank.value;
            } else {
                const width = state.picture?.crop_data?.width;
                if (width >= 2500) {
                    quality_rank_value = "1";
                } else if (width >= 1500 && width < 2500) {
                    quality_rank_value = "2";
                } else {
                    quality_rank_value = "3";
                }
            }
            const tagsQuality = tags.filter((item) => item.group.id === 7);
            if (tagsQuality && tagsQuality.length > 0) {
                tagsQuality.forEach((item) => {
                    item.tags.forEach((tag) => {
                        if (quality_rank_value === tag.label) {
                            setValue("7", tag.value);
                        }
                    });
                });
            }
        }
    }, [state]);

    const [tagsGetty, setTagsGetty] = useState([]);
    useEffect(() => {
        if (show) {
            const keywordsTexts = state.picture?.metas
                .filter((item) => item.key === "keywords")
                .map((item) => JSON.parse(item.value).text);
            setTagsGetty(keywordsTexts);
        }
    }, [show, state]);

    const onSubmit = (data, direction) => {
        return api
            .post(
                `picture/${id_picture_version}/tags`,
                { locale },
                { tags: data }
            )
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "common.pictureTagsModal.success"
                    )
                );
                onSuccess?.(direction);
                setShowEnvironmentWarning(0);
                setShowCropWarning(0);
                setShowColourWarning(0);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const handleDelete = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "common.pictureTagsModal.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "common.pictureTagsModal.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            handleHide();
            setShowEnvironmentWarning(0);
            setShowCropWarning(0);
            setShowColourWarning(0);
        });
    };

    return (
        <PictureTagsModal
            {...props}
            {...state}
            show={show}
            tags={tags}
            errors={errors}
            isDirty={isDirty}
            control={control}
            showEnvironmentWarning={showEnvironmentWarning}
            showCropWarning={showCropWarning}
            showColourWarning={showColourWarning}
            setShowEnvironmentWarning={setShowEnvironmentWarning}
            setShowCropWarning={setShowCropWarning}
            setShowColourWarning={setShowColourWarning}
            tagsGetty={tagsGetty}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(onSubmit)}
            handleHide={handleHide}
            handleDelete={handleDelete}
            locale={locale}
            location={location}
        />
    );
};

export default EnhancedPictureTagsModal;
