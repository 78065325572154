export const PARTNERS = [
    "7D",
    "AZ",
    "DG",
    "DZ",
    "IT",
    "MB",
    "NA",
    "PR",
    "QB",
    "UN",
    "SP",
];

export const STATUS_MATCHED_IN_CURRENT_DISCOGRAPHY =
    "MATCHED_IN_CURRENT_DISCOGRAPHY";
export const STATUS_MATCHED_IN_OTHER_DISCOGRAPHY =
    "MATCHED_IN_OTHER_DISCOGRAPHY";
export const STATUS_NOT_MATCHED_COMPLETE = "NOT_MATCHED_COMPLETE";
export const STATUS_NOT_MATCHED_INCOMPLETE = "NOT_MATCHED_INCOMPLETE";

export const STATUSES = [
    STATUS_MATCHED_IN_CURRENT_DISCOGRAPHY,
    STATUS_MATCHED_IN_OTHER_DISCOGRAPHY,
    STATUS_NOT_MATCHED_COMPLETE,
    STATUS_NOT_MATCHED_INCOMPLETE,
];
