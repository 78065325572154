import React, { useState } from "react";
import Discography from "./Discography";
import { DiscographyProvider } from "./Discography.provider";

const EnhancedDiscography = (props) => {
    const [covers, setCovers] = useState({
        musicstory: {
            url: "http://images.music-story.com/img/jaquette-defaut-album-400.jpg",
            origin: "Music Story",
        },
        partners: [],
    });

    return (
        <DiscographyProvider>
            <Discography {...props} covers={covers} setCovers={setCovers} />
        </DiscographyProvider>
    );
};

export default EnhancedDiscography;
