import React from "react";
import LazyLoad from "react-lazyload";
import Loader from "shared/components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import TagsButton from "shared/components/Picture/Action/TagsButton";
import TagsVersionButton from "shared/components/Picture/Action/TagsVersionButton";
import CropButton from "shared/components/Picture/Action/CropButton";
import DeleteButton from "shared/components/Picture/Action/DeleteButton";
import clsx from "clsx";
import ImageLoader from "shared/components/ImageLoader";
import Tippy from "@tippyjs/react";
import I18n from "shared/lib/I18n";
import "./Picture.css";

const Picture = ({ picture, location, getPictures }) => {
    return (
        <div
            className={clsx(
                "misc-entity-management-getty-card",
                "card",
                "border"
            )}
        >
            <LazyLoad
                height="100%"
                placeholder={<Loader />}
                debounce={200}
                once
            >
                <div className="image-container">
                    <ImageLoader
                        src={picture.url_version + "?r=" + Math.random()}
                        fallbackSrc={picture.url + "?r=" + Math.random()}
                        className="card-img-top"
                    />
                    <div className="image-content-over">
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <a
                                className="d-flex"
                                href={picture.url_version || picture.url}
                                target="_blank"
                            >
                                <span className="text-white">
                                    <FontAwesomeIcon
                                        icon={faEye}
                                        size="2x"
                                        fixedWidth
                                    />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </LazyLoad>
            <div className="card-body p-2">
                <p className="card-text d-flex justify-content-around align-items-center">
                    <span>
                        <Tippy
                            content={I18n.getTranslation(
                                location,
                                "misc_entity.management.imageGetty.gallery.width"
                            )}
                            trigger="mouseenter"
                        >
                            <span>{picture.width}</span>
                        </Tippy>
                        <span className="mx-1">x</span>
                        <Tippy
                            content={I18n.getTranslation(
                                location,
                                "misc_entity.management.imageGetty.gallery.height"
                            )}
                            trigger="mouseenter"
                        >
                            <span>{picture.height}</span>
                        </Tippy>
                    </span>
                    <span>
                        <Tippy
                            content={I18n.getTranslation(
                                location,
                                "misc_entity.management.imageGetty.gallery.created_at"
                            )}
                            trigger="mouseenter"
                        >
                            <span>{picture.created_at || "-"}</span>
                        </Tippy>
                    </span>
                </p>
            </div>
            <div className="card-body p-2 border-top">
                <p className="card-text text-center">
                    <span>
                        <Tippy
                            content={I18n.getTranslation(
                                location,
                                "misc_entity.management.imageGetty.gallery.copyright"
                            )}
                            trigger="mouseenter"
                        >
                            <span>{picture.copyright || "-"}</span>
                        </Tippy>
                    </span>
                </p>
            </div>
            {picture.tags.length > 0 && (
                <div className="card-body px-3 pt-1 pb-1 border-top">
                    <div className="row list-tags no-gutters">
                        {picture.tags.map(({ group, name }) => (
                            <span
                                key={group}
                                className={`col badge badge-light border m-1`}
                            >
                                {name}
                            </span>
                        ))}
                    </div>
                </div>
            )}
            <div className="card-body p-2 border-top">
                <p className={`card-text`}>
                    <Tippy
                        content={I18n.getTranslation(
                            location,
                            "misc_entity.management.imageGetty.gallery.status"
                        )}
                        trigger="mouseenter"
                    >
                        <span
                            className={`col badge badge-${
                                picture.id_version ? "success" : "primary"
                            } badge-lg border`}
                        >
                            {picture.id_version ? "DOCUMENTED" : "AWAITING"}
                        </span>
                    </Tippy>
                </p>
            </div>
            <div className="card-footer d-flex justify-content-around">
                <TagsButton
                    pictureId={picture.id_picture}
                    versionId={picture.id_picture_original}
                />
                {picture.id_version && (
                    <TagsVersionButton
                        pictureId={picture.id_picture}
                        versionId={picture.id_version}
                        onSuccess={getPictures}
                    />
                )}
                <CropButton
                    pictureId={picture.id_picture}
                    versionId={picture.id_version}
                    onSuccess={getPictures}
                />
                <DeleteButton
                    pictureId={picture.id_picture}
                    onSuccess={getPictures}
                />
            </div>
        </div>
    );
};

export default Picture;
