import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useContext,
} from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import useApi from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { MiscEntityManagementContext } from "pages/MiscEntityManagement";
import Getty from "./Getty";

const defaultState = {
    isLoading: false,
    pictures: [],
};

const EnhancedGetty = (props) => {
    const { api } = useApi();
    const { miscEntityId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState(defaultState);

    const getPictures = useCallback(() => {
        let cancelled = false;

        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        api.get(`miscentity/${miscEntityId}/picture/getty`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        isLoading: false,
                        pictures: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setState(defaultState);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [miscEntityId, locale]);

    useEffect(getPictures, [getPictures]);

    const { miscEntity } = useContext(MiscEntityManagementContext);
    const defaultSearch = useMemo(
        () =>
            Object.entries(miscEntity).length > 0
                ? `"${miscEntity.name}"`
                : null,
        [miscEntity]
    );

    return (
        <Getty
            {...props}
            {...state}
            defaultSearch={defaultSearch}
            getPictures={getPictures}
            miscEntityId={miscEntityId}
        />
    );
};

export default EnhancedGetty;
