import React from "react";
import I18n from "shared/lib/I18n";
import Tippy from "@tippyjs/react";
import ImageLoader from "shared/components/ImageLoader";
import { Nav, Tab, OverlayTrigger, Popover } from "react-bootstrap";
import { ProgressBar } from "react-bootstrap";
import clsx from "clsx";
import InputSwitch from "shared/components/Form/InputSwitch";
import ArtistLink from "shared/components/ArtistLink";
import AlbumLink from "shared/components/AlbumLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import placeholder from "assets/static/images/logo_placeholder.png";
import Featuring from "./Profile/Featuring";
import Instrument from "./Profile/Instrument";
import Duration from "./Profile/Duration";
import Isrc from "./Profile/Isrc";
import RelatedIsrc from "./Profile/RelatedIsrc";
import Translation from "./Profile/Translation";
import Genre from "./Profile/Genre";
import Albums from "./Profile/Albums";
import Charts from "./Charts";
import Lyrics from "./Lyrics";
import Audio from "./Audio";
import Credits from "./Credits";
import Popularity from "./Popularity";
import { Helmet } from "react-helmet";
import Widget from "shared/components/Widget";
import "./RecordingView.css";

const RecordingView = ({
    recording,
    headerObserverRef,
    isHeaderSticky,
    selectedTab,
    onSelectTab,
    location,
    containerRef,
    containerTopOffset,
    redirectToEdit,
    user,
}) => {
    return (
        <div className="content-wrap recording-view">
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    {recording.artists && (
                        <li className="breadcrumb-item">
                            {recording.artists?.map((artist, index) => {
                                return (
                                    <React.Fragment key={artist.id}>
                                        {index !== 0 && (
                                            <span className="mx-1">-</span>
                                        )}
                                        <ArtistLink
                                            artistId={artist.id}
                                            content={artist.name}
                                            view
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </li>
                    )}
                    {recording.album?.id && (
                        <li className="breadcrumb-item">
                            <AlbumLink
                                albumId={recording.album.id}
                                content={recording.album.name}
                            />
                        </li>
                    )}
                    <li className="breadcrumb-item active">
                        <I18n t="recording.view.breadcrumb" />
                    </li>
                </ol>
                <div id="header-observer" ref={headerObserverRef}></div>
                {recording?.id && (
                    <div
                        id="header"
                        className={clsx(
                            "d-flex header",
                            isHeaderSticky && "header-sticky"
                        )}
                    >
                        <OverlayTrigger
                            placement="auto"
                            overlay={
                                <Popover id={`popover-positioned-top`}>
                                    <Popover.Content className="bg-black rounded-sm">
                                        <ImageLoader
                                            src={recording?.album?.cover}
                                            fallbackSrc={placeholder}
                                            style={{
                                                width: "400px",
                                                height: "400px",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </Popover.Content>
                                </Popover>
                            }
                        >
                            <span>
                                <ImageLoader
                                    src={recording?.album?.cover}
                                    fallbackSrc={placeholder}
                                    fallbackClassName="placeholder-img"
                                    className="header-img border"
                                />
                            </span>
                        </OverlayTrigger>
                        <div className="header-data d-flex flex-column justify-content-around flex-grow-1">
                            <h4 className="mb-0">
                                <Tippy
                                    content={I18n.getTranslation(
                                        location,
                                        "recording.view.id"
                                    )}
                                    trigger="mouseenter"
                                >
                                    <span>{recording.id}</span>
                                </Tippy>
                            </h4>
                            <h2 className="mb-0">
                                <strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "recording.view.title"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span>{recording.title}</span>
                                    </Tippy>
                                </strong>
                                {recording?.subtitle && (
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "recording.view.subtitle"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span> - {recording.subtitle}</span>
                                    </Tippy>
                                )}
                            </h2>
                            <h4 className="mb-0">
                                {recording.artists &&
                                    recording.artists.map((artist, index) => {
                                        return (
                                            <React.Fragment key={artist.id}>
                                                {index !== 0 && (
                                                    <span className="mx-1">
                                                        -
                                                    </span>
                                                )}
                                                <ArtistLink
                                                    artistId={artist.id}
                                                    content={artist.name}
                                                    view
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                            </h4>
                            {recording?.genre && (
                                <h4 className="mb-0">
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "recording.view.genre"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span>{recording.genre}</span>
                                    </Tippy>
                                </h4>
                            )}
                        </div>
                        <div
                            className={clsx(
                                "d-flex flex-column",
                                isHeaderSticky && "p-3"
                            )}
                        >
                            <div className="flex-grow-1">
                                {user.hasRight("artist.disco.manage") &&
                                    recording.album?.id && (
                                        <div
                                            className={clsx(
                                                "d-flex align-items-center justify-content-end",
                                                isHeaderSticky && "mr-2"
                                            )}
                                        >
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                fixedWidth
                                            />
                                            <InputSwitch
                                                checked={false}
                                                onChange={redirectToEdit}
                                            />
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                fixedWidth
                                            />
                                        </div>
                                    )}
                            </div>
                            {recording.hit && (
                                <h4 className="d-flex align-items-baseline justify-content-end">
                                    <I18n t="album.view.profileTracklist.hit" />
                                    <span className="mx-1">:</span>
                                    <div style={{ width: "60px" }}>
                                        <Tippy
                                            content={`${recording.hit}/5`}
                                            trigger="mouseenter"
                                        >
                                            <ProgressBar
                                                now={
                                                    (parseInt(
                                                        recording.hit,
                                                        10
                                                    ) /
                                                        5) *
                                                    100
                                                }
                                                variant="purple-ms"
                                                style={{
                                                    height: "10px",
                                                    background: "lightgrey",
                                                }}
                                            />
                                        </Tippy>
                                    </div>
                                </h4>
                            )}
                            {recording.editorial_pick && (
                                <h4 className="d-flex align-items-baseline justify-content-end">
                                    <I18n t="album.view.profileTracklist.editorial_pick" />
                                    <span className="mx-1">:</span>
                                    <div style={{ width: "60px" }}>
                                        <Tippy
                                            content={`${recording.editorial_pick}/5`}
                                            trigger="mouseenter"
                                        >
                                            <ProgressBar
                                                now={
                                                    (parseInt(
                                                        recording.editorial_pick,
                                                        10
                                                    ) /
                                                        5) *
                                                    100
                                                }
                                                variant="purple-ms"
                                                style={{
                                                    height: "10px",
                                                    background: "lightgrey",
                                                }}
                                            />
                                        </Tippy>
                                    </div>
                                </h4>
                            )}
                        </div>
                    </div>
                )}
                <Tab.Container
                    id="recording-view-tab"
                    activeKey={selectedTab}
                    defaultActiveKey="profile"
                    onSelect={onSelectTab}
                    mountOnEnter
                    unmountOnExit
                >
                    <Nav
                        variant="pills"
                        className={clsx(
                            "recording-view-nav",
                            isHeaderSticky && "nav-sticky"
                        )}
                        justify
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="profile">
                                <I18n t="recording.view.profile" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="credits">
                                <I18n t="recording.view.credits" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="popularity">
                                <I18n t="recording.view.popularity" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="charts">
                                <I18n t="recording.view.charts" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="lyrics">
                                <I18n t="recording.view.lyrics" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="audio_description">
                                <I18n t="recording.view.audio_description" />
                            </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                            <Nav.Link eventKey="similar">
                                <I18n t="recording.view.similar" />
                            </Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                    <Tab.Content
                        ref={containerRef}
                        className={clsx(
                            "recording-view-nav-content nav-content overflow-hidden"
                        )}
                        style={{
                            marginTop: isHeaderSticky
                                ? `${containerTopOffset}px`
                                : "1.2rem",
                        }}
                    >
                        <Tab.Pane eventKey="profile" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "recording.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "recording.view.profile"
                                )}`}</title>
                            </Helmet>
                            <Widget>
                                <Widget.Body>
                                    <>
                                        <div className="row">
                                            <div className="col border-right">
                                                <Isrc
                                                    isrc={recording?.isrc}
                                                    parental_advisory={
                                                        recording?.parental_advisory
                                                    }
                                                />
                                            </div>
                                            <div className="col border-right">
                                                <Duration
                                                    duration={
                                                        recording?.duration
                                                    }
                                                />
                                            </div>
                                            {recording?.genre && (
                                                <div className="col">
                                                    <Genre
                                                        genre={recording.genre}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <hr className="my-2" />
                                        <div className="row">
                                            <div className="col border-right">
                                                <RelatedIsrc />
                                            </div>
                                            <div className="col border-right">
                                                <Translation />
                                            </div>
                                            <div className="col border-right">
                                                <Featuring />
                                            </div>
                                            <div className="col">
                                                <Instrument />
                                            </div>
                                        </div>
                                    </>
                                </Widget.Body>
                            </Widget>
                            <div className="row">
                                <div className="col">
                                    <Albums />
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="credits" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "recording.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "recording.view.credits"
                                )}`}</title>
                            </Helmet>
                            <Credits />
                        </Tab.Pane>
                        <Tab.Pane eventKey="popularity" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "recording.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "recording.view.popularity"
                                )}`}</title>
                            </Helmet>
                            <Popularity id_recording={recording?.id} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="charts" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "recording.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "recording.view.charts"
                                )}`}</title>
                            </Helmet>
                            <Charts />
                        </Tab.Pane>
                        <Tab.Pane eventKey="lyrics" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "recording.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "recording.view.lyrics"
                                )}`}</title>
                            </Helmet>
                            <Lyrics />
                        </Tab.Pane>
                        <Tab.Pane
                            eventKey="audio_description"
                            className="nav-pane"
                        >
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "recording.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "recording.view.audio_description"
                                )}`}</title>
                            </Helmet>
                            <Audio />
                        </Tab.Pane>
                        {/* <Tab.Pane
                            eventKey="similar"
                            className="nav-pane text-center"
                        >
                            <h1>COMING SOON</h1>
                        </Tab.Pane> */}
                    </Tab.Content>
                </Tab.Container>
            </main>
        </div>
    );
};

export default RecordingView;
